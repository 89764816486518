import React from 'react';
import { Layout, SEO } from 'components/common';
import { Intro, Skills, Contact, Leistungen, Reviews } from 'components/landing';

export default () => (
  <Layout>
    <SEO />
    <Intro />
    {/* <Leistungen /> */}
    {/* <Skills />
    <Reviews />
    <Contact /> */}
  </Layout>
);
