import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';
import { Row, Col } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Header, Footer } from 'components/theme';
import { Container, Button } from 'components/common';
import { Contact } from 'components/landing/Contact';
import CookieConsent from 'react-cookie-consent';
import logo from 'assets/illustrations/LOGO-WHITE.svg';
import box1 from 'assets/illustrations/ICON1BG.svg';
import box2 from 'assets/illustrations/ICON2BG.svg';
import box3 from 'assets/illustrations/ICON3BG.svg';
import fairies from 'assets/illustrations/FAIRIES1.svg';
import fairies2 from 'assets/illustrations/FAIRIES2.svg';
import servicePakete from 'assets/illustrations/SERVICEPAKETE-SMALL.svg';
import building from 'assets/illustrations/element53.jpg';
import element47 from 'assets/illustrations/Element47.png';
import element46 from 'assets/illustrations/Element46.png';
import servicepaketelarge from 'assets/illustrations/SERVICEPAKETE-LARGE.svg';
import map from 'assets/illustrations/MAP.png';
import { Link } from 'gatsby';
import Card from 'react-bootstrap/Card';
import {
  LandingPageWrapper,
  IntroWrapper,
  Details,
  HeroImageAbout,
  Section2BackgroundIntro,
  Section3BackgroundIntro,
  Section4BackgroundIntro,
  Section5BackgroundIntro,
  Section6BackgroundIntro,
  Section7BackgroundIntro,
  Section8BackgroundIntro,
} from './styles';

export const Intro = () => (
  <LandingPageWrapper>
    <Header />
    <HeroImageAbout>
      <IntroWrapper as={Container}>
        <Details>
          <img className="logoWhite" src={logo} alt="RD Hausfee logo" height="963" />
          <h4 className="title1 animate__animated animate__slideInDown">BRINGT</h4>
          <h4 className="title2 animate__animated animate__slideInDown">IHR HAUS</h4>
          <h4 className="title2 animate__animated animate__slideInDown">& SIE</h4>
          <h4 className="title3 animate__animated animate__slideInDown">ZUM STRAHLEN</h4>
          <div className="buttonWrapper">
            <AnchorLink href="#contact">
              <Button className="buttonHome2">ANGEBOT EINHOLEN</Button>
            </AnchorLink>
            <a href="mailto:office@rd-hausfee.at">
              <Button className="buttonHome">TERMIN BUCHEN</Button>
            </a>
          </div>
        </Details>
      </IntroWrapper>
    </HeroImageAbout>
    <IntroWrapper as={Container}>
      <Details>
        <div className="h1Overview">
          <div className="alignText">
            <h2 className="dancingScript animate__animated animate__slideInRight">
              Ich weiß jetzt meine drei Wünsche - <br /> wer hat die Fee?
            </h2>
            <p className="lato36">
              Hausfee bietet kompetente Reinigung und Betreuung von Privat- und Gewerbeimmobilien im Gebiet St. Pölten
              Land. Wir bringen ihr Haus und Sie zum Strahlen.
            </p>
            <Row xs={1} md={3}>
              <Col className="paddingBottom10">
                <img src={box1} alt="perfekte Reinigung" />
              </Col>
              <Col className="paddingBottom10">
                <img src={box2} alt="Rundumservice" />
              </Col>
              <Col className="paddingBottom10">
                <img src={box3} alt="Zufriedenheitsgarantie" />
              </Col>
            </Row>
          </div>
        </div>
      </Details>
    </IntroWrapper>
    <Section2BackgroundIntro>
      <IntroWrapper as={Container}>
        <Details>
          <div className="previewColumns positionRelative">
            <h3 className="dancingScript56">
              Wir sind Ihre <br /> guten Feen!
            </h3>
            <img className="fairies" src={fairies} alt="perfekte Reinigung" height="250" />
            <Row>
              <Col md="6" xs="12">
                <p className="lato21">
                  Mein Name ist Durdica Rasic. Gemeinsam mit meinem Team unterstützen wir Sie in der Erhaltung Ihres
                  Privathauses oder Ihrer Gewerbeimmobilie. Damit Sie mehr Zeit für die schönen Dinge im Leben haben.
                  Unser Service umfasst alle wichtigen Aufgaben im Haushalt und darüber hinaus.
                </p>
              </Col>
              <Col md="6" xs="12">
                <p className="lato21right">
                  Als Ihr kompetenter Partner können Sie sich voll und ganz auf uns verlassen. Wir unterstützen Sie
                  engagiert und gründlich in der Haushaltsführung. Außerdem betreuen wir Stiegenhaus, Keller, Straße und
                  vieles mehr.
                </p>
              </Col>
            </Row>
            <div className="buttonWrapper">
              <Link to="/about">
                <Button className="buttonMehrErfahen">MEHR ERFAHREN</Button>
              </Link>
            </div>
          </div>
        </Details>
      </IntroWrapper>
    </Section2BackgroundIntro>
    <Section3BackgroundIntro>
      <IntroWrapper as={Container}>
        <Details>
          <div className="h3Vorteile">
            <div className="vorteileColumns">
              <h3 className="dancingScript56">Ihre Vorteile</h3>
              <img className="fairies2" src={fairies2} alt="Fairies" />
              <Row xs={1} md={3} noGutters>
                <Col className="width50">
                  <Card>
                    <Card.Body>
                      <img className="banner1" src={servicePakete} alt="individuelle Servicepakete" />
                      <Card.Subtitle className="mb-2 text-muted titleUnderline">
                        MASSGESCHNEIDERTE <br /> SERVICEPAKETE
                      </Card.Subtitle>
                      <Card.Text>
                        Unsere Servicepakete <br /> werden individuell auf Sie <br /> und Ihre Bedürfnisse <br />{' '}
                        abgestimmt. So sparen Sie <br /> bares Geld und erhalten <br /> exakt jene Leistungen, die{' '}
                        <br />
                        Sie brauchen.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="width50 cardPadding">
                  <Card className="cardPosition">
                    <Card.Body>
                      <Card.Subtitle className="mb-2 text-muted titleUnderline">
                        MEHR ZEIT <br /> FÜR SICH
                      </Card.Subtitle>
                      <Card.Text>
                        Wir kümmern uns um Ihre <br /> Reinigungsangelegenheiten, <br /> während Sie entspannt die{' '}
                        <br /> Dinge tun, die Ihnen <br />
                        wirklich wichtig sind. <br /> So gewinnen Sie mehr Zeit <br /> für sich und Ihre Lieben.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="width50 paddingTop10">
                  <Card className="cardPosition cardFloatRight">
                    <Card.Body>
                      <Card.Subtitle className="mb-2 text-muted titleUnderline">
                        WERTSICHERUNG <br /> IHRER IMMOBILIE
                      </Card.Subtitle>
                      <Card.Text>
                        Eine regelmäßige, <br /> umfassende Reinigung Ihrer <br /> Privat- oder <br /> Gewerbeimmobilie
                        trägt zur <br /> Werterhaltung bei. <br /> Verlassen Sie sich auf unsere <br /> langjährige
                        Erfahrung!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Details>
      </IntroWrapper>
    </Section3BackgroundIntro>
    <Section4BackgroundIntro>
      <IntroWrapper as={Container}>
        <Details>
          <div className="h4Section">
            <div className="previewColumns">
              <Row xs={1} md={2}>
                <Col>
                  <img className="building" src={building} alt="Building" />
                </Col>
                <Col>
                  <h3 className="dancingScript56 paddingTop20">Gewerbekunden</h3>
                  <p className="lato21 paddingBottom30">
                    Für unsere Gewerbekunden bieten wir <br /> außerdem Stiegenhaus- und Kellerreinigung <br /> sowie
                    Reinigung von Zinshäusern und <br /> Mietobjekten an.
                  </p>
                  <p className="lato21 paddingBottom30">
                    Wir haben auch ganz besondere Services auf <br /> Lager: zum Beispiel eine einmalige <br />{' '}
                    Grundreinigung nach Sanierung/Renovierung <br /> oder den jährlichen Frühjahrsputz.
                  </p>
                  <p className="lato21 paddingBottom30">
                    Lassen Sie sich von unseren individuellen <br /> Servicepaketen begeistern und lehnen Sie sich{' '}
                    <br /> entspannt zurück während wir Ihre Immobilie <br /> auf Hochglanz polieren.
                  </p>
                  <div className="paddingBottom10">
                    <span className="floatLeft">
                      <Link to="/gewerbekunden">
                        <Button className="buttonMehrLesenWhite">MEHR LESEN</Button>
                      </Link>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Details>
      </IntroWrapper>
    </Section4BackgroundIntro>
    <Section5BackgroundIntro>
      <IntroWrapper as={Container}>
        <Details>
          <div className="h5Privatkunden">
            <div className="previewColumns">
              <div className="row">
                <div className="columnPrivatkunden1">
                  <h3 className="dancingScript56 privatkunden">Privatkunden</h3>
                  <p className="lato21 privatkunden">
                    <span className="emptyLine">
                      Unsere Privatkunden untersützen wir liebevoll und umfassend in allen Bereichen der
                      Haushaltsführung.
                    </span>
                    <span className="emptyLine">
                      Mit uns können Sie reden! Bei aller Präzision vergessen wir nicht darauf, dass wir mit Menschen
                      arbeiten. Und Menschen verdienen einen liebevollen, respektvollen Umgang
                    </span>
                    <span className="emptyLine">
                      Unsere Mission ist es ein Umfeld voller Glück und Wohlbefinden zu schaffen. Uns geht das Herz auf,
                      wenn wir es schaffen Ihnen ein Lächeln ins Gesicht zu zaubern.
                    </span>
                  </p>
                </div>
                <div className="columnPrivatkunden2">
                  <img className="element46" src={element46} alt="Cleaning supplies" />
                  <img className="element47" src={element47} alt="Broom" />
                </div>
                <div className="paddingBottom10">
                  <span className="floatLeft">
                    <Link to="/privatkunden">
                      <Button className="buttonMehrLesen">MEHR LESEN</Button>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Details>
      </IntroWrapper>
    </Section5BackgroundIntro>
    <Section6BackgroundIntro>
      <IntroWrapper as={Container} className="paddingBottom0">
        <Details>
          <div className="einsatzgebiet">
            <div className="previewColumns">
              <div className="row">
                <div className="column">
                  <h3 className="dancingScript56">Unser Einsatzgebiet</h3>
                  <p className="lato21">
                    <span className="emptyLine">
                      Wir sind in den Gebieten Wien, Wien Umgebung <br /> und St. Pölten Land flexibel für Sie
                      unterwegs. <br />
                    </span>
                    <span className="emptyLine">
                      Gegen einen Fahrtkostenaufpreis fahren wir <br /> auch gern noch ein Stück weiter.
                    </span>
                  </p>
                </div>
                <div className="column">
                  <img className="austriaMap" src={map} alt="Map of Austria" />
                </div>
              </div>
            </div>
          </div>
        </Details>
      </IntroWrapper>
    </Section6BackgroundIntro>
    <Section7BackgroundIntro>
      <IntroWrapper as={Container}>
        <Details className="positionRelative">
          <div className="h7servicepakete">
            <div className="previewColumns">
              <Row>
                <Col>
                  <h4 className="title7">UNSERE INDIVIDUELLEN SERVICEPAKETE</h4>
                  <p className="lato21">
                    <span className="emptyLine">
                      Statt komplizierter Preislisten und Abrechnung jeden kleinsten Handgriffs bieten wir ihnen einen
                      sinnvollen Rundumservice. Unsere Preise setzen sich nach Ihren tatsächlich benötigten Leistungen
                      zusammen und werden speziell für Sie nach Maß kalkuliert. Unsere Preisgestaltung ist fair,
                      transparent und folgt unserem Leitbild: mehr Hausverstand. Lassen Sie sich von unseren
                      einzigartigen individuellen Servicepaketen begeistern!
                    </span>
                  </p>
                </Col>
                <Col>
                  <img className="servicepakete" src={servicepaketelarge} alt="Servicepakete" />
                </Col>
              </Row>
            </div>
          </div>
        </Details>
      </IntroWrapper>
    </Section7BackgroundIntro>
    <Section8BackgroundIntro>
      <Details>
        <Contact></Contact>
        <Footer />
      </Details>
    </Section8BackgroundIntro>
    <CookieConsent
      location="bottom"
      buttonText="Ich stimme zu"
      cookieName="myAwesomeCookieName2"
      style={{ background: '#95c357' }}
      buttonStyle={{ color: '#95c357', fontSize: '13px', background: '#fff' }}
    >
      Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in
      unserer <a href="/impressum">Datenschutzerklärung</a> am Ende der Seite. Klicken Sie auf „Ich stimme zu“, um
      Cookies zu akzeptieren.
    </CookieConsent>
  </LandingPageWrapper>
);
