import styled from 'styled-components';

export const Details = styled.div`
  .formTitle {
    color: rgb(112 112 112);
    width: 419px;
    height: 63px;
    @media screen and (max-width: 600px) {
      width: inherit;
      height: inherit;
    }
  }

  input#form-input-control-email,
  input#form-input-control-last-name,
  textarea#form-textarea-control-opinion {
    border: 2px solid #95c357;
  }

  textarea#form-textarea-control-opinion {
    height: 63px;
  }

  .required.field.formTitle label {
    float: left;
    color: rgb(112 112 112);
  }

  .required.field.formTitle {
    @media (min-width: 450px) and (max-width: 960px) {
      display: contents;
    }
  }

  .emptyLine {
    margin-top: 1em;
    display: block;
    float: right;
    text-align: end;
    @media screen and (max-width: 600px) {
      float: none;
      text-align: center;
    }
  }

  .contactButton {
    // position: absolute;
    // display: block;
    // left: 850px;
    display: block;
    margin-top: 50px;
    font-family: Lato;
    width: 193px;
    height: 63px;
    background: #95c357;
    color: #ffff;
    border: 2px solid;
    color: #ffff;
    border-color: #ffff;
    border-radius: 7px;
    &:hover {
      background: white;
      color: #95c357;
      transition: 0.3s ease-in;
      border-color: #95c357;
      float: none;
    }
    @media screen and (max-width: 600px) {
      float: none;
    }
  }

  .ui.left.pointing.prompt.label {
    background: #fff !important;
    color: #9f3a38 !important;
    border: #fff !important;
    whitespaces: normal !important;
  }

  .disabledButton {
    // position: absolute;
    // display: block;
    // left: 850px;
    display: block;
    pointer-events: none;
    margin-top: 50px;
    font-family: Lato;
    width: 193px;
    height: 63px;
    background: #cccccc;
    color: #ffff;
    border: 2px solid;
    color: #ffff;
    border-color: #ffff;
    border-radius: 7px;
    @media screen and (max-width: 600px) {
      float: none;
    }
  }
`;

export const ContacFrom = styled.span`
  .swal2-styled.swal2-cancel {
    background-color: hsl(182deg 91% 34%) !important;
  }
`;

export const Center = styled.div`
  text-align: left;

  h4 {
    font-weight: normal;
  }
`;

export const Wrapper = styled.div`
  padding-top: 4rem;
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  .boldGreen {
    color: ${({ theme }) => (theme === 'light' ? '#08a0a5' : '#08a0a5')};
  }
`;

export const Button = styled.div`
  font-family: Lato;
  border: 2px solid;
  color: #ffff;
  border-color: #ffff;
  border-radius: 7px;
  width: fit-content;
  text-align: center;
  padding: 5px;
  min-width: 120px;
  &:hover {
    background: white;
    color: #c6b7c1;
    transition: 0.3s ease-in;
  }
`;
