/* eslint-disable no-console */
import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, TextArea } from 'semantic-ui-react';
import EmailValidator from 'email-validator';
import swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';
import hausfeeLogo from 'assets/illustrations/RDHAUSFEE_logo.png';
import { Button } from 'components/common';
import { Details } from './styles';

class ContactForm extends Component {
  state = {
    email: '',
    name: '',
    message: '',
    verified: false,
    validEmail: true,
  };

  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }
    const styleLink = document.createElement('link');
    styleLink.rel = 'stylesheet';
    styleLink.href = 'https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css';
    document.head.appendChild(styleLink);
  }

  emailValidation = e => {
    this.setState({ email: e.target.value });
    this.setState({ validEmail: EmailValidator.validate(e.target.value) });
  };

  sendEmail = e => {
    e.preventDefault();
    console.log('send email');

    emailjs.sendForm('service_5oc1oms', 'template_yvfht1v', e.target, 'user_UUXmh5xsXaoGUWHe4dyn6').then(
      result => {
        console.log(result.text);
        swal.fire('Message sent', 'Danke für Ihre Nachricht! Wir melden uns so bald wie möglich bei Ihnen.', 'success');
      },
      error => {
        console.log(error.text);
        swal.fire('Message Error', error.text, 'error');
      }
    );
    e.target.reset();
  };

  handleChange = e => {
    const nam = e.target.name;
    const val = e.target.value;
    if (nam === 'user_email') {
      this.setState({ email: val });
    }
    if (nam === 'user_name') {
      this.setState({ name: val });
    }
    if (nam === 'user_message') {
      this.setState({ message: val });
    }
  };

  recaptchaLoaded = response => {
    if (response) {
      this.setState({ verified: true });
    }
  };

  render() {
    const { email, name, message, verified, validEmail } = this.state;

    return (
      <div className="contact-form-card">
        <Details className="paddingTop0">
          <img className="hausfeeLogo" src={hausfeeLogo} alt="Hausfee logo" />
          <Row xs={1} md={2}>
            <Col md="6" xs="12" className="borderRight">
              <p className="floatRight">
                <span>
                  Kontaktieren Sie <br /> uns noch heute! <br />
                </span>
                <span className="emptyLine">
                  office@rd-hausfee.at <br /> +43 664 166 1589
                </span>
              </p>
            </Col>
            <Col md="6" xs="12">
              <Form onSubmit={this.sendEmail}>
                <Form.Field
                  id="form-input-control-email"
                  className="formTitle"
                  control={Input}
                  label="Email"
                  name="user_email"
                  placeholder="Ihre E-Mail-Adresse"
                  required
                  icon="mail"
                  iconPosition="left"
                  onChange={this.emailValidation}
                  error={
                    validEmail
                      ? false
                      : {
                          content: 'Bitte geben Sie eine gültige Email-Adresse',
                          pointing: 'left',
                        }
                  }
                />
                <Form.Field
                  id="form-input-control-last-name"
                  className="formTitle"
                  control={Input}
                  label="Name"
                  name="user_name"
                  placeholder="Ihr Name"
                  required
                  icon="user circle"
                  iconPosition="left"
                  onChange={this.handleChange}
                />
                <Form.Field
                  id="form-textarea-control-opinion"
                  className="formTitle"
                  control={TextArea}
                  label="Nachricht"
                  name="user_message"
                  placeholder="Ihre Nachricht"
                  required
                  onChange={this.handleChange}
                />
                <ReCAPTCHA sitekey="6LckuvAZAAAAAHadsEgRY9yuQjaMSD0Af1J59qde" onChange={this.recaptchaLoaded} />
                <Button
                  className={
                    !verified || !email || !name || !message || !validEmail ? 'disabledButton' : 'contactButton'
                  }
                >
                  ABSENDEN
                </Button>
              </Form>
              {/* {email && name && message && validEmail && (
                <ReCAPTCHA sitekey="6LckuvAZAAAAAHadsEgRY9yuQjaMSD0Af1J59qde" onChange={this.recaptchaLoaded} />
              )} */}
            </Col>
          </Row>
        </Details>
      </div>
    );
  }
}

export default ContactForm;

// export default () => (
//   <Formik
//     initialValues={{
//       name: '',
//       email: '',
//       message: '',
//       recaptcha: '',
//       success: false,
//     }}
//     validationSchema={Yup.object().shape({
//       name: Yup.string().required('Full name field is required'),
//       email: Yup.string()
//         .email('Invalid email')
//         .required('Email field is required'),
//       message: Yup.string().required('Message field is required'),
//       recaptcha: Yup.string().required('Robots are not welcome yet!'),
//     })}
//     // onSubmit={async ({ name, email, message }, { setSubmitting, resetForm, setFieldValue }) => {
//     //   try {
//     //     await axios({
//     //       method: 'POST',
//     //       url: `${process.env.GATSBY_PORTFOLIO_FORMIK_ENDPOINT}`,
//     //       headers: {
//     //         'Content-Type': 'application/json',
//     //       },
//     //       data: JSON.stringify({
//     //         name,
//     //         email,
//     //         message,
//     //       }),
//     //     });
//     //     setSubmitting(false);
//     //     setFieldValue('success', true);
//     //     setTimeout(() => resetForm(), 3000);
//     //   } catch (err) {
//     //     setSubmitting(false);
//     //     setFieldValue('success', false);
//     // 		alert('Something went wrong, please try again!') // eslint-disable-line
//     //   }
//     // }}
//     onSubmit={sendEmail}
//   >
//     {({ values, touched, errors, setFieldValue, isSubmitting }) => (
//       <Form>
//         <InputField>
//           <Input
//             as={FastField}
//             type="text"
//             name="name"
//             component="input"
//             aria-label="name"
//             placeholder="Full name*"
//             error={touched.name && errors.name}
//           />
//           {console.log(values)}
//           <ErrorMessage component={Error} name="name" />
//         </InputField>
//         <InputField>
//           <Input
//             id="email"
//             aria-label="email"
//             component="input"
//             as={FastField}
//             type="email"
//             name="email"
//             placeholder="Email*"
//             error={touched.email && errors.email}
//           />
//           <ErrorMessage component={Error} name="email" />
//         </InputField>
//         <InputField>
//           <Input
//             as={FastField}
//             component="textarea"
//             aria-label="message"
//             id="message"
//             rows="8"
//             type="text"
//             name="message"
//             placeholder="Message*"
//             error={touched.message && errors.message}
//           />
//           <ErrorMessage component={Error} name="message" />
//         </InputField>
//         {values.name && values.email && values.message && (
//           <InputField>
//             <FastField
//               component={Recaptcha}
//               sitekey="6LclA_IZAAAAAH1hFKF63487TKU6gv9EsYwtLtH4"
//               name="recaptcha"
//               onChange={value => setFieldValue('recaptcha', value)}
//             />
//             <ErrorMessage component={Error} name="recaptcha" />
//           </InputField>
//         )}
//         {values.success && (
//           <InputField>
//             <Center>
//               <h4>Your message has been successfully sent, I will get back to you ASAP!</h4>
//             </Center>
//           </InputField>
//         )}
//         <Center>
//           <Button
//             type="submit"
//             disabled={
//               !values.name || !values.email || (touched.email && errors.email) || !values.recaptcha || values.success
//             }
//           >
//             Submit
//           </Button>
//         </Center>
//       </Form>
//     )}
//   </Formik>
// );
