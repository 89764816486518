import styled from 'styled-components';
import overlayIllustration from 'assets/illustrations/Element-44.png';
import gewerbekundenImage from 'assets/illustrations/Element45.png';
import element48 from 'assets/illustrations/Element48.png';

// export const LandingPageWrapper = styled.div`
//   padding-bottom: 4rem;
//   background-image: url(${overlayIllustration});
//   background-size: contain;
//   height: 963px;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   position: relative;
//   align-items: center;
// `;

export const LandingPageWrapper = styled.div`
  height: 963px;
  h4.title1About,
  h4.title2About {
    font-size: 96px;
    font-family: Lato;
    background: rgb(192 166 176 / 66%);
    color: #ffff;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
    padding: 10px;
    margin-bottom: 10px;
    float: left;
    @media screen and (min-width: 601px) {
      font-size: 80px;
    }
    @media screen and (max-width: 600px) {
      font-size: 60px;
    }
  }

  .height1520 {
    height: 1520px;
    @media screen and (max-width: 600px) {
      height: auto;
    }
    @media (min-width: 450px) and (max-width: 960px) {
      height: auto;
    }
  }

  .dancingScript64About {
    padding-bottom: 53px;
    font-family: 'Dancing Script';
    font-size: 64px;
    text-align: left;
    color: #c0a6b0;
    @media screen and (max-width: 600px) {
      text-align: center;
      font-size: 34px;
    }
  }

  .buttonAnfrageAbout {
    font-family: Lato;
    width: 250px;
    height: 70px;
    margin-bottom: 20px;
    background: #ffffff;
    color: #c0a6b0;
    border-radius: 7px;
  }

  .buttonAngebot {
    font-family: Lato;
    width: 250px;
    height: 70px;
    margin-left: 20px;
    margin-bottom: 20px;
    background: transparent;
    color: #ffff;
    border: 2px solid;
    color: #ffff;
    border-color: #ffff;
    border-radius: 7px;
    &:hover {
      background: white;
      color: #c0a6b0;
      transition: 0.3s ease-in;
      border-color: #c0a6b0;
    }
    @media screen and (max-width: 600px) {
      margin-left: 0px;
      float: none;
    }
  }
`;

export const HeroImageAbout = styled.div`
  background-image: url(${overlayIllustration});
  background-size: contain;
  height: 963px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

// export const IntroWrapper = styled.div`
//   padding-top: 160px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   @media (max-width: 960px) {
//     flex-direction: column;
//   }
//   color: #707070;
// `;

export const IntroWrapper = styled.div`
  padding-top: 160px;
  display: inherit;
  align-items: center;
  justify-content: space-between;
  width: 58%;
  padding-bottom: 53px;

  @media (max-width: 960px) {
    flex-direction: column;
    width: 80%;
  }

  @media (min-width: 993px) {
    width: 58%;
  }

  @media (max-width: 601px) {
    width: 90%;
    padding-top: 80px;
  }

  color: #707070;

  .positionRelative {
    position: relative;
    align-items: center;
  }

  .positionAbsolute {
    position: absolute;
    right: 23vw;
    top: 15vw;
    @media screen and (max-width: 600px) {
      right: 3vw;
      top: 24vw;
      width: 50vw;
    }

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
      right: 14vw;
      top: 20vw;
    }
  }
`;

export const Section2BackgroundIntro = styled.div`
  width: 100%;
  background: #95c357;
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: auto;
  }

  @media (min-width: 450px) and (max-width: 960px) {
    height: auto;
  }
`;

export const Section3BackgroundIntro = styled.div`
  width: 100%;
  background: #c0a6b0;
  height: 840px;
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: auto;
  }

  @media (min-width: 450px) and (max-width: 960px) {
    height: auto;
  }
`;

export const Section4BackgroundIntro = styled.div`
  height: 1030px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(36, 109, 119, 0.74), rgba(36, 106, 105, 0.74)), url(${gewerbekundenImage});
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: auto;
  }

  @media (min-width: 450px) and (max-width: 960px) {
    height: auto;
  }

  .paddingTop20 {
    @media screen and (max-width: 600px) {
      padding-top: 20px;
    }
  }
`;

export const Section5BackgroundIntro = styled.div`
  width: 100%;
  background: #ffffff;
  height: 1030;
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: auto;
  }

  @media (min-width: 450px) and (max-width: 960px) {
    height: auto;
  }
`;

export const Section6BackgroundIntro = styled.div`
  width: 100%;
  background: #c0a6b0;
  padding-bottom: 160px;
  @media screen and (max-width: 600px) {
    height: auto;
    padding-bottom: 80px;
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: auto;
    padding-bottom: 80px;
  }

  @media (min-width: 450px) and (max-width: 960px) {
    height: auto;
    padding-bottom: 80px;
  }

  .paddingBottom0 {
    padding-bottom: 0px;
  }
`;

export const Section7BackgroundIntro = styled.div`
  height: 930px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(36, 109, 119, 0.74), rgba(36, 106, 105, 0.74)), url(${element48});
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: auto;
  }

  @media (min-width: 450px) and (max-width: 960px) {
    height: auto;
  }
`;

export const Section8BackgroundIntro = styled.div`
  height: 930px;
  width: 100%;
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    height: auto;
  }

  @media (min-width: 450px) and (max-width: 960px) {
    height: auto;
  }
`;

export const Details = styled.div`
  flex: 1;
  text-align: center;

  .alignText {
    text-align: start;
    padding-bottom: 160px;
    @media screen and (max-width: 600px) {
      padding-bottom: 0px;
    }
    @media (min-width: 450px) and (max-width: 960px) {
      padding-bottom: 0px;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: ${({ theme }) => (theme === 'light' ? '#fff' : '#fff')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? '#fff' : '#fff')};
    }

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  .paddingBottom10 {
    padding-bottom: 10px;
  }

  h4.title1 {
    font-size: 96px;
    background-color: #c8b7c1a3;
    color: #ffff;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
    padding: 10px;
    margin-bottom: 10px;
    @media screen and (min-width: 601px) {
      font-size: 80px;
    }
    @media screen and (max-width: 600px) {
      font-size: 50px;
    }
  }

  h4.title2 {
    font-size: 96px;
    background-color: #04a1a7a8;
    color: #ffff;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
    padding: 10px;
    margin-bottom: 10px;
    @media screen and (min-width: 601px) {
      font-size: 80px;
    }
    @media screen and (max-width: 600px) {
      font-size: 50px;
    }
  }

  h4.title3 {
    font-size: 96px;
    background-color: #95c3578f;
    color: #ffff;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
    padding: 10px;
    margin-bottom: 0;
    @media screen and (min-width: 601px) {
      font-size: 80px;
    }
    @media screen and (max-width: 600px) {
      font-size: 50px;
    }
  }

  .logoWhite {
    height: 165px;
    margin-left: auto;
    margin-right: 0;
  }

  h2 {
    color: ${({ theme }) => (theme === 'light' ? '#fff' : '#fff')};
  }

  .boldGreen {
    color: ${({ theme }) => (theme === 'light' ? '#08a0a5' : '#efeeff')};
  }

  .buttonWrapper {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .buttonMehrLesen {
    float: left;
    font-family: ;
    width: 250px;
    height: 70px;
    background: #6b6b6b;
    color: #ffff;
    border: 2px solid;
    color: #ffff;
    border-color: #ffff;
    border-radius: 7px;
    &:hover {
      background: white;
      color: #6b6b6b;
      transition: 0.3s ease-in;
      border-color: #6b6b6b;
      float: none;
    }
    @media screen and (max-width: 600px) {
      float: none;
    }
  }

  .cardPadding {
    @media (min-width: 601px) and (max-width: 960px) {
      padding-left: 25px;
      width: 50%;
      float: none;
    }
  }

  .floatLeft {
    float: left;
    @media screen and (max-width: 600px) {
      float: none;
    }
  }

  .buttonMehrLesenWhite {
    font-family: Lato;
    width: 250px;
    height: 70px;
    background: #ffffff;
    color: #0ca1a7;
    border: 2px solid;
    border-color: #ffff;
    border-radius: 7px;
    &:hover {
      background: none;
      color: #ffffff;
      transition: 0.3s ease-in;
      border-color: #ffffff;
      float: none;
    }
    @media screen and (max-width: 600px) {
      float: none;
      padding-bottom: 10px;
    }
  }

  .buttonHome {
    width: 250px;
    height: 70px;
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
    background: #ffff;
    color: #95c357;
    font-family: Lato;
  }

  .buttonHome2 {
    font-family: Lato;
    width: 250px;
    height: 70px;
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
    background: transparent;
    color: #ffff;
    border: 2px solid;
    color: #ffff;
    border-color: #ffff;
    border-radius: 7px;
    &:hover {
      background: white;
      color: #95c357;
      transition: 0.3s ease-in;
      border-color: #95c357;
    }
  }

  .buttonMehrErfahen {
    font-family: Lato;
    width: 250px;
    height: 70px;
    background: transparent;
    color: #ffff;
    border: 2px solid;
    color: #ffff;
    border-color: #ffff;
    border-radius: 7px;
    &:hover {
      background: white;
      color: #95c357;
      transition: 0.3s ease-in;
      border-color: #95c357;
    }
  }
  h2.dancingScript {
    padding-bottom: 53px;
    font-family: 'Dancing Script';
    font-size: 64px;
    padding-bottom: 53px;
    color: #95c357;
    @media screen and (max-width: 600px) {
      font-size: 40px;
    }
  }

  .dancingScript56 {
    padding-bottom: 53px;
    font-family: 'Dancing Script';
    font-size: 56px;
    text-align: left;
    @media screen and (max-width: 600px) {
      text-align: center;
      font-size: 40px;
    }

    @media (min-width: 601px) and (max-width: 960px) {
      font-size: 48px;
    }
  }

  .previewColumns {
    color: #ffff;
    max-width: 1280px;
    margin: 0 auto;
  }

  .vorteileColumns {
    color: #ffff;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    @media (min-width: 601px) and (max-width: 960px) {
      padding-top: 0px;
    }
    @media screen and (max-width: 600px) {
      padding-top: 0px;
    }
  }

  .lato36 {
    font-family: 'Lato';
    font-size: 36px;
    padding-bottom: 53px;
    @media screen and (max-width: 600px) {
      font-size: 21px;
    }
  }

  .lato21 {
    font-family: 'Lato';
    font-size: 21px;
    padding-bottom: 53px;
    text-align: left;
  }

  .paddingBottom30 {
    padding-bottom: 30px;
  }

  .lato21right {
    font-family: 'Lato';
    font-size: 21px;
    padding-bottom: 53px;
    text-align: left;
    float: right;
  }

  .h1Overview {
    height: fit-content;
    width: 100%;
  }

  .row {
    display: flex;
  }

  .rowCards {
    display: flex;
    clear: both;
    @media screen and (max-width: 600px) {
      display: inline-grid;
    }
  }

  .column {
    flex: 33.33%;
    width: 322px;
    @media screen and (max-width: 600px) {
      flex: 50%;
    }
  }

  .columnPrivatkunden1 {
    flex: 33.33%;
    width: 322px;
    @media screen and (max-width: 600px) {
      flex: 50%;
    }
  }

  .columnPrivatkunden2 {
    flex: 33.33%;
    width: 322px;
    position: relative;
    @media screen and (max-width: 600px) {
      flex: 50%;
    }
  }

  .h2AboutPreview {
    height: 850px;
    width: 100%;
    background: #95c357;
    position: relative;
    @media screen and (max-width: 600px) {
      height: fit-content;
    }
  }

  .h5Privatkunden {
    height: 1030;
    width: 100%;
    background: #ffffff;
    @media screen and (max-width: 600px) {
      height: fit-content;
    }
  }

  .privatkunden {
    color: #6b6b6b;
  }

  .h3Vorteile {
    width: 100%;
    background: #c0a6b0;
    @media screen and (max-width: 600px) {
      height: fit-content;
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 32pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === 'light' ? '#fff' : '#fff')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? '#fff' : '#fff')};
    }

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }

  .card-columns {
    @include media-breakpoint-only(lg) {
      column-count: 4;
    }
    @include media-breakpoint-only(xl) {
      column-count: 5;
    }
  }

  .titleUnderline {
    display: inline-block;
    /* font-size: 3em; */
    padding-bottom: 20px;
    background: linear-gradient(#656161 0 0) 50% 100% /50px 2px;
    background-repeat: no-repeat;
    font-size: 21px;
    font-family: Lato;
  }

  .card {
    width: 313px;
    height: 414px;
    margin: 0px;
    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
      display: inline-block;
    }
    @media (min-width: 601px) and (max-width: 960px) {
      height: 300px;
    }

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
      width: 18rem;
    }
  }

  .card-text {
    color: #656161;
    font-family: Lato;
    padding-top: 10px;
  }

  .cardPosition {
    display: inline-flex;
  }

  .width50 {
    @media (min-width: 601px) and (max-width: 960px) {
      width: 50%;
    }
  }

  .paddingTop10 {
    @media (min-width: 601px) and (max-width: 960px) {
      padding-top: 10px;
    }
  }

  .paddingTop0 {
    padding-top: 0px;
  }

  .cardFloatRight {
    float: right;
    @media screen and (max-width: 600px) {
      float: none;
    }
    @media (min-width: 601px) and (max-width: 960px) {
      float: none;
    }
  }

  img.banner1 {
    height: 200px;
    position: absolute;
    margin-left: 180px;
    margin-bottom: 35px;
    bottom: 280px;
    left: 83px;
    z-index: 9;
    @media screen and (max-width: 600px) {
      height: 130px;
      position: absolute;
      z-index: 9;
      top: 343px;
      margin-left: 7px;
      left: 200px;
    }
    @media (min-width: 601px) and (max-width: 960px) {
      height: 144px;
      position: absolute;
      margin-left: 180px;
      margin-bottom: 35px;
      bottom: 180px;
      left: 83px;
      z-index: 9;
    }

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
      height: 150px;
      position: absolute;
      margin-left: 180px;
      margin-bottom: 35px;
      bottom: 322px;
      left: 44px;
      z-index: 9;
    }
  }

  img.fairies2 {
    height: 14vw;
    position: absolute;
    top: -50px;
    right: -50px;
    @media screen and (max-width: 600px) {
      height: 10rem;
      position: inherit;
      left: 0px;
      top: 0px;
      margin: 0px auto;
      padding-bottom: 15px;
    }
    @media (min-width: 601px) and (max-width: 960px) {
      height: 30vw;
      position: absolute;
      top: -184px;
      right: 0px;
    }

    @media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
      height: 300px;
      position: absolute;
      top: -200px;
      right: -50px;
    }
  }

  img.fairies {
    height: 22rem;
    position: absolute;
    right: 12vw;
    bottom: 24vw;
    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
      height: 22rem;
      position: absolute;
      right: -2vw;
      bottom: 34vw;
    }
    @media screen and (max-width: 600px) {
      height: 9rem;
      position: absolute;
      bottom: 230vw;
      margin: 0px auto;
      padding-bottom: 15px;
    }

    @media (min-width: 601px) and (max-width: 960px) {
      height: 13rem;
      position: absolute;
      left: 40vw;
      top: -13vw;
    }
  }

  .h4Section {
    width: 100%;
    // background-image: linear-gradient(rgba(36, 109, 119, 0.74), rgba(36, 106, 105, 0.74)), url(${gewerbekundenImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 600px) {
      height: fit-content;
    }
  }

  img.building {
    width: 531px;
    height: 702px;
    @media screen and (max-width: 600px) {
      display: inline;
      height: 300px;
      width: 200px;
    }
  }

  .frontColumns {
    position: relative;
  }

  .emptyLine {
    margin-top: 1em;
    display: block;
    margin-bottom: 2rem;
  }

  .element46 {
    float: right;
    position: relative;
    border: 15px solid #f4f7f0;
    width: 361px;
    height: 498px;
    @media screen and (max-width: 600px) {
      width: inherit;
      height: inherit;
      bottom: 70px;
    }
  }

  .element47 {
    position: absolute;
    border: 15px solid #f4f7f0;
    top: 320px;
    right: 64px;
    width: 400px;
    @media screen and (max-width: 600px) {
      width: 260px;
      height: inherit;
      top: 205px;
      left: 14px;
    }

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
      right: 40px;
    }

    @media (min-width: 450px) and (max-width: 960px) {
      left: 50px;
    }
  }

  .title7 {
    font-size: 96px;
    color: #ffff;
    text-align: initial;
    width: fit-content;
    @media screen and (max-width: 600px) {
      font-size: 36px;
    }
    @media (min-width: 450px) and (max-width: 960px) {
      font-size: 56px;
    }
  }

  .einsatzgebiet {
    background-color: #c0a6b0;
  }

  .austriaMap {
    float: right;
  }

  .h7servicepakete {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .servicepakete {
    height: 350px;
    position: absolute;
    right: 0px;
    @media screen and (max-width: 600px) {
      height: 100px;
      bottom: 700px;
    }
    @media (min-width: 450px) and (max-width: 960px) {
      height: 200px;
      top: -54px;
      position: absolute;
    }
    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
      height: 250px;
      right: -200px;
    }
  }

  .positionRelative {
    position: relative;
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

