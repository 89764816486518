import React from 'react';
import { Container } from 'components/common';
import { Col } from 'react-bootstrap';
import { Wrapper, Details } from './styles';
import ContactForm from './ContactForm/index';

export const Contact = () => (
  <Wrapper as={Container} id="contact">
    <Details className="paddingTop0">
      <Col>
        <ContactForm />
      </Col>
    </Details>
  </Wrapper>
);
